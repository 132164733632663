@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-item {
  @apply relative flex flex-row justify-start gap-5 items-center lg:h-24;
}

.nav-item-horizontal {
  @apply h-16 w-16 !justify-center;
}

.nav-item-container:not(:last-child) .nav-item:before {
  @apply content-[''] bg-black absolute lg:h-full lg:w-[3px] lg:top-2/4 lg:left-0 lg:-translate-x-2/4;
}

.nav-item-container:not(:last-child) .nav-item-horizontal:before {
  @apply w-full h-[3px] left-2/4 top-2/4 -translate-y-2/4;;
}

.nav-item {
  cursor: not-allowed;
}

.nextup .nav-item, .complete .nav-item, .active .nav-item {
  cursor: pointer;
}

.nav-item-title {
  opacity: 0.5;
  pointer-events: none;
  
}

.nextup .nav-item-title, .complete .nav-item-title, .active .nav-item-title {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.nav-item-step {
  @apply w-10 h-10 absolute justify-center items-center z-10 bg-slate-900 rounded-full font-semibold text-white flex lg:flex-col lg:left-[-1.25rem] 
}

.nav-item-step-horizontal {
  @apply flex-row;
}

.nav-item-title {
  @apply ml-8 text-left;
}

.active .nav-item-step {
  @apply bg-blue-500 !important;
}

.complete .nav-item-step {
  @apply bg-green-400;
}

/* .accordion .item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
}

.accordion .item:hover {
  flex: 7;
}

.accordion .item:hover img {
  object-fit: contain;
} */

.item-1 { 
  background-image: url('https://images.unsplash.com/photo-1499198116522-4a6235013d63?auto=format&fit=crop&w=1233&q=80');
}

.item-2 { 
  background-image: url('https://images.unsplash.com/photo-1492760864391-753aaae87234?auto=format&fit=crop&w=1336&q=80');
}

.item-3 { 
  background-image: url('https://images.unsplash.com/photo-1503631285924-e1544dce8b28?auto=format&fit=crop&w=1234&q=80');
}

.item-4 { 
  background-image: url('https://images.unsplash.com/photo-1510425463958-dcced28da480?auto=format&fit=crop&w=1352&q=80');
}

.item-5 { 
  background-image: url('https://images.unsplash.com/photo-1503602642458-232111445657?auto=format&fit=crop&w=1234&q=80');
}